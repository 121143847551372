import React from 'react'

//Components
import SEO from '../components/SEO/seo'
import Layout from '../components/Layout/layout'
import Section from '../components/Section/section'
import GetStarted from  '../components/GetStarted/getStarted'
import PricingItem from '../components/Pricing/pricingItem'

//utils
import {IG_PRICING_DATA} from '../utils/constants'

class PricingPage extends React.Component {

    __renderPricing () {
        return <div className='container website__vp text-center py-5 rounded' style={{background: '#feeecd'}}>
            <div className='row'>
                <article className='col-12'>
                    <h2 className='h1 website__vp-title mb-0'>Guarantee your A today</h2>
                    <p className='mb-3'>Start mastering IGCSE Maths now</p>
                    <p>All our content is specifically written for Cambridge IGCSE, GCSE and O-Levels Maths.<br/>Learn exactly everything you need to know.</p>
                </article>
                <div className='col-12'>
                    <div className='row justify-content-center text-center'>
                        {
                            IG_PRICING_DATA.map((data,index)=>(
                                <PricingItem {...data} key={index}/>
                            ))
                        }
                    </div>
                    <p className='mt-4 text-small mb-1'>To unlock the rest of the course upgrade within the app.</p>
                    <p className='text-small'>Having trouble? Contact us at <a href='mailTo:support@quicksense.org'>support@quicksense.org</a></p>
                </div>
            </div>
        </div>
    }

    render(){
        return <>
            <SEO title='Pricing'/>
            <Layout classes='fw-landing-page'>
                <Section >
                    {this.__renderPricing()}
                </Section>
                <GetStarted/>
            </Layout>
        </>
    }
}

export default PricingPage