import React from 'react'
import PropTypes from 'prop-types'

//Components
import Button from '../Button/button'

//utils
import { SIGN_UP_LINK } from '../../utils/constants'

const PricingItem = ({plan, price, benefits}) => (
    <div className='website__pricing'>
        <div className='website__pricing-header'>
            <p className='website__pricing-plan'>{plan}</p>
        </div>

        <div className='website__pricing-amount'>
            <p className='website__pricing-dollar'>£<span>{price} </span></p>
            <p className='website__pricing-header'>{price>0 ? 'one-off payment' : <span>&nbsp;</span>}</p>
        </div>

        <ul className='website__pricing-benefits'>
            {
                benefits.map((data,index)=>(
                    <li className='website__pricing-benefits-item' key={index}>{data}</li>
                ))
            }
        </ul>

        <div className='website__pricing-footer d-flex flex-column'>
            <Button href={SIGN_UP_LINK} eventValues={{location: `${price > 0 ? 'Paid Pricing' : 'Free Pricing'}`, page: 'Pricing'}} classes={`btn ${price > 0 ? 'btn-primary' : 'btn-outline-primary'} website__pricing-action `}>Try for free</Button>
            <small className='website__pricing-header mt-3'>No credit card required</small>
        </div>
    </div>
)

export default PricingItem

PricingItem.propTypes = {
    plan: PropTypes.string,
    price: PropTypes.number,
    benefits: PropTypes.array
}